import _get from 'lodash/get';
import _kebabCase from 'lodash/kebabCase';
import { withoutTrailingSlash } from './util';

/* provider_profile is a v8 provider object */
export function getProviderProfilePathV8(providerProfile) {
  const fullName =
    _get(providerProfile, 'name.full_name', '') ||
    _get(providerProfile, 'name.full', '');
  const urlSafeProviderName = encodeURIComponent(_kebabCase(fullName));
  return `/provider/${urlSafeProviderName}/${encodeURIComponent(
    providerProfile.id
  )}`;
}

export function getProviderProfileUrlV8(host, providerProfile) {
  return `${withoutTrailingSlash(host)}${getProviderProfilePathV8(
    providerProfile
  )}`;
}

// get canonical path to a given v9 provider's profile page
export function getProviderProfilePath(provider = {}) {
  const fullName = _get(provider, 'name.full', '');
  const urlSafeProviderName = encodeURIComponent(_kebabCase(fullName));
  return `/provider/${urlSafeProviderName}/${encodeURIComponent(provider.id)}`;
}

// get canonical path to a given v9 provider's profile page
export function getProviderProfileUrl(host, provider = {}) {
  return `${withoutTrailingSlash(host)}${getProviderProfilePath(provider)}`;
}
