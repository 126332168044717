import { messagesToApptPurposeFilterLabels } from './messages';

export const getApptPurposeFilterProps = ({ config = {}, intl }) => {
  const minPurposesForFilter = parseFloat(
    config.minimum_appointment_purposes_for_filter
  );
  return {
    minPurposesForFilter: Number.isNaN(minPurposesForFilter)
      ? Number.POSITIVE_INFINITY
      : Math.ceil(minPurposesForFilter),
    labels: messagesToApptPurposeFilterLabels(intl)
  };
};
