import { TOKENS } from '../tracking/persisted-tokens';
import { type TokensStore } from '../behaviors/tokens/types';

/**
 * Get query params to append to dbw /book urls
 * @param  {Object} config - customer service config
 * @param  {Object} tokens - tokens object from the store
 * @returns {Object} object with query params
 */
export function getDbwTrackingParams({
  config,
  tokens
}: {
  config: any;
  tokens: TokensStore;
}) {
  const { locale } = config;

  const params: Record<string, string> = {};

  if (tokens) {
    const tokenKeys = Object.keys(TOKENS) as Array<keyof typeof TOKENS>; // copy all persisted tokens onto the dbw url.
    // don't copy everything from 'tokens' because not all tokens need to be persisted
    tokenKeys.forEach((key) => {
      if (key in tokens && typeof tokens[key] === 'string') {
        const paramName = TOKENS[key].queryParam;
        params[paramName] = tokens[key] as string;
      }
    });
  }

  if (locale === 'es-US') {
    params.locale = config.locale;
  }

  // if we added any params, also add timestamp for validation
  if (Object.keys(params).length > 0) {
    params.timestamp = `${new Date().getTime()}`;
  }

  return params;
}
