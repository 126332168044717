import { defineMessages } from '@kyruus/intl';

export const messages = defineMessages({
  noResults: {
    id: 'appt_purpose.filtering.no_results',
    description:
      'Text to be displayed by the appointment purpose filtering search box when no results are found',
    defaultMessage: 'No options match your selected criteria'
  },
  purposeSearch: {
    id: 'appt_purpose.filtering.purpose_search',
    description: 'The label for the appointment purpose filtering search box',
    defaultMessage: 'Filter by purpose of visit'
  },
  purposeSearchPlaceholder: {
    id: 'appt_purpose.filtering.purpose_search_placeholder',
    description:
      'The placeholder text for the appointment purpose filtering search box',
    defaultMessage: 'Filter by purpose of visit'
  },
  visitTypeAll: {
    id: 'appt_purpose.filtering.visit_type_all',
    description:
      'Text to be displayed by the appointment purpose filtering visit type selector for the "All" selection',
    defaultMessage: 'All'
  },
  visitTypeInPerson: {
    id: 'appt_purpose.filtering.visit_type_in_person',
    description:
      'Text to be displayed by the appointment purpose filtering visit type selector for the "In Person" selection',
    defaultMessage: 'In Person'
  },
  visitTypeVirtual: {
    id: 'appt_purpose.filtering.visit_type_virtual',
    description:
      'Text to be displayed by the appointment purpose filtering visit type selector for the "Virtual" selection',
    defaultMessage: 'Virtual Visit'
  },
  visitTypePhone: {
    id: 'appt_purpose.filtering.visit_type_phone',
    description:
      'Text to be displayed by the appointment purpose filtering visit type selector for the "Phone" selection',
    defaultMessage: 'Phone Visit'
  },
  clearFilters: {
    id: 'appt_purpose.filtering.clear_filters',
    description:
      'Text to be displayed by the appointment purpose filter for the button that clears existing filters',
    defaultMessage: 'Clear filters'
  },
  filterSummary: {
    id: 'appt_purpose.filtering.filter_summary',
    description:
      'Text to be displayed by the appointment purpose filter showing the number of matching results out of the possible total results',
    defaultMessage: 'Showing {count} of {total}'
  }
});

export const messagesToApptPurposeFilterLabels = (intl) => ({
  noResultsLabel: intl.formatMessage(messages.noResults),
  purposeSearchLabel: intl.formatMessage(messages.purposeSearch),
  purposeSearchPlaceholderLabel: intl.formatMessage(
    messages.purposeSearchPlaceholder
  ),
  visitTypeAllLabel: intl.formatMessage(messages.visitTypeAll),
  visitTypeInPersonLabel: intl.formatMessage(messages.visitTypeInPerson),
  visitTypeVirtualLabel: intl.formatMessage(messages.visitTypeVirtual),
  visitTypePhoneLabel: intl.formatMessage(messages.visitTypePhone),
  clearFiltersLabel: intl.formatMessage(messages.clearFilters),
  filterSummaryLabel: (count, total) =>
    intl.formatMessage(messages.filterSummary, { count, total })
});
